import React from 'react';
import { itemsServices } from '../datas/itemsServices';

function Services() {
    return (
        <>
                 <section className="services" id="services">
        <div className="max-width">
            <h2 className="title">Services</h2>
            <div className="serv-content">
			

            {itemsServices.map((service, index)=>(
                <div className="card" key={index}>
                    <div className="box">
                      
						<i className={service.icon} aria-hidden="true"></i>
                        <div className="text">{service.name}</div>
                        <p>{service.content}</p>
                    </div>
                </div>
            ))}
				
               
            </div>
        </div>
    </section>
            
        </>
    );
}

export default Services;