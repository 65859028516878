export const ItemsSkills = [

    {name: 'Serveurs :', 
    description: 'Active Directory, DHCP, DNS, Ftp, Web, SCCM;'}, 

    {name: 'Réseaux :', 
    description: 'Protocoles et services, TCP/IP, IPV4, DHCP, DNS, VoIP;'}, 

    {name: 'Système d\'exploitation :', 
    description: 'Windows,Linux;'}, 

    {name: 'Programmation :' , 
    description: 'JavaScript, typeScript, PHP, NodeJS, Python;'}, 

    {name: 'Frameworks :', 
    description: 'Bootstrap, AngularJs, ASP.Net, Laravel; ReactJs;'}, 
    
    {name: 'Base de données :', 
    description: 'MySQL, SQL Server; MongoDB;'}, 

    {name: 'Versionning :', 
    description: 'Git, Github;'}, 
    
    {name: 'Intégration graphique :', 
    description: 'HTML/CSS3/jQuery;'}, 

]



