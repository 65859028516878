
export const ItemsProjects = [
    {icon: 'react',
    id:3,
    name:'Reactjs',
    content:'Application réalisée avec la libraire ReactJs'},

    {icon: 'mern',
    id:4,
    name:'MERN',
    content:'Application combinant MongoDB - Express - React - Node'},

    {icon: 'laravel',
    id:5,
    name:'Laravel',
    content:'Framework PHP'},

    {icon: 'js',
    id:0,
    name:'JS, Ajax, PHP',
   content:'SPA réalisée avec JavaScript, Ajax effectuant toutes les opérations du CRUD'},

   {icon: 'mysql',
   id:1,
   name:'PHP, MySQL',
  content:'Projet de site de e-commerce avec le framework Laravel. Il implemente le modèle MVC'},

  {icon: 'alpine2',
  id:2,
  name:'Alpine Js',
 content:'Application réalisée dans le contexte Covid-19. Aide à la vérification des preuves vaccinales'},

 
{icon: 'react2',
id:6,
name:'Jeux',
content:'Application de jeux de mémoire'},


]