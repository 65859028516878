export const itemsNavbar = [

    {
        cible:'#home',
        name: 'Accueil'
    },
    {
        cible:'#about',
        name: 'À propos'
    },
    {
        cible:'#services',
        name: 'Services'
    },
    {
        cible:'#skills',
        name: 'Skills'
    },
    {
        cible:'#projets',
        name:'Projets'
    },
    {
        cible:'#contact',
        name: 'Contact'
    },

]


