import React from 'react';
import { ItemsSkills } from '../datas/itemsSkills';

function Skills() {
    return (
        <>
                    <section className="skills" id="skills">
        <div className="max-width">
            <h2 className="title">Compétences</h2>
            <div className="skills-content">
                <div className="column left">
                    <div className="text">Compétences & expériences.</div>
                   
					<ul>
                        {ItemsSkills.map((item, index)=>(
                            <li key={index}><strong>{item.name}</strong> {item.description}</li>
                            ))}


					</ul>
					
					
					
					
					
					
               
                </div>
                <div className="column right">
                    <div className="bars">
                        <div className="info">
                            <span>Systèmes d'exploitation </span>
                            <span>90%</span>
                        </div>
                        <div className="line html"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span>Active Directory</span>
                            <span>80%</span>
                        </div>
                        <div className="line css"></div>
                    </div>

                    <div className="bars">
                        <div className="info">
                            <span>CSS</span>
                            <span>80%</span>
                        </div>
                        <div className="line css"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span>JavaScript</span>
                            <span>80%</span>
                        </div>
                        <div className="line js"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span>PHP</span>
                            <span>50%</span>
                        </div>
                        <div className="line php"></div>
                    </div>


                    <div className="bars">
                        <div className="info">
                            <span>MySQL</span>
                            <span>70%</span>
                        </div>
                        <div className="line mysql"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            
        </>
    );
}

export default Skills;