import React from 'react';
import Typed from 'typed.js';
import { useEffect } from 'react';

function Home() {


    
    useEffect(()=>{

    // typing text animation script
    const typed = new Typed(".typing", {
        //   strings: ["YouTuber", "Developer", "Blogger", "Designer", "Freelancer"],
           stringsElement: '#typed-strings',
           typeSpeed: 100,
           backSpeed: 60,
           loop: true
       });

       // Destropying
         return () => {
                typed.destroy();
        };

    }, []);

            


    return (
        <>
    <section className="home" id="home">
        <div className="max-width">
            <div className="home-content">
                <div className="text-1">Bonjour, je suis</div>
                <div className="text-2">Taliby FOFANA.</div>
				<div id="typed-strings"> 
					<p> <span>Web developer, Technical support  & designer.</span></p>
					<p ><span style={{color:'#ffff'}}> J'aime  le </span> code  <span style={{color:'#ffff'}}>et le </span> soutien technique </p>
				</div>
                <div className="text-3"><span className="typing"></span></div>
                
				<div className="social-media">
					<a href="https://github.com/dekalossy" target="_blank" rel="noopener noreferrer"><i className="fa fa-github-square" /></a>
					<a href="https://ca.linkedin.com/in/taliby-fofana-559929a3" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin-square" /></a>
					<a href="#twitter"><i className="fa fa-twitter" ></i></a>
				</div>
            </div>
        </div>
    </section>
            
        </>
    );
}

export default Home;