import React from 'react';
import '../assets/styles/css/style.css';
import alpine2 from '../assets/images/alpine2.jpg';
import mern2 from '../assets/images/mern2.jpg';
import laravel from '../assets/images/laravel.png';
import react2 from '../assets/images/react.png';
import mysql2 from '../assets/images/mysql.png';
import js2 from '../assets/images/js.png';
import Slider from "react-slick";
import { ItemsProjects } from '../datas/itemsProjects';

const images = [js2, mysql2, alpine2,  react2, mern2, laravel, react2];

const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };




function Projects() {

    

    return (
        <>
                
    <section className="projets owl-theme" id="projets">
        <div className="max-width">
            <h2 className="title">Projets</h2>


            <div className="carousel owl-carousel">

<Slider {...settings}>

                        {ItemsProjects.map((item, index)=>(

                                    <div className="card" key={index} >
                                        <div className="box" style={{height: '19rem'}}>
                                            <img src= { images[item.id]} alt="logo"/>
                                            <div className="text">{item.name} </div>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>

                        ))}
</Slider>    


            </div>
        </div>
    </section>
    
        </>
    );
}

export default Projects;