import React from 'react';


import infra from '../assets/images/infrastructure.png';

function About() {
    return (
        <>
            <section className="about" id="about">
            <div className="max-width">
                <h2 className="title">À propos</h2>
                <div className="about-content">
                    <div className="column left">
                        <img src={infra} alt="infra"/>
                    </div>
                    <div className="column right">
                        <div className="text">Je suis Taliby Fofana, <span className="typing-2"></span></div>
                        <p>Passionné d’informatique avec plus d’une dizaine d’années d’expérience, j’ai de solides connaissances dans le soutien technique aux usagers, les réseaux informatiques ainsi que dans la création et l’optimisation de contenu de site web.<br/> Ma bonne connaissance des bases de données me permet de travailler de façon autonome pour sélectionner et organiser efficacement l'information en vue de son intégration et de concevoir l'apparence, l'agencement et les flux de données du site Web. Ma polyvalence et ma capacité d’apprentissage combinées à mes habiletés communicationnelles et relationnelles sont gages d’intégration et de travail collaboratif</p>
                        <a href="http://localhost:3001/CV_Taliby_Fofana_Tech_Support_Dev_Nov_2023.pdf" download="cv-Fofana-Taliby.pdf"  target="_blank" rel="noopener noreferrer" >Curriculum-vitae</a>
                        
                    </div>
                </div>
            </div>
    </section>
            
        </>
    );
}

export default About;