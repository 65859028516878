import React, { useEffect, useState } from 'react';
import appleIcon from '../assets/images/apple-icon-60x60.png';
import {itemsNavbar} from '../datas/itemsNavbar';


function Navbar() {


    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
                                const position = window.scrollY;
                                setScrollPosition(position);
                                
                                };
    

    const [navbarMenuToggle, setnavbarMenuToggle] = useState('');      
   // const [menuBtnToggle, setmenuBtnToggle] = useState(true);   
    
    const handleClick = ()=>{

        navbarMenuToggle === '' ?  setnavbarMenuToggle('active') : setnavbarMenuToggle('');

                        };

 


    const [myClassName, setmyClassName] = useState("navbar"); 
    const [myClassName2, setmyClassName2] = useState("scroll-up-btn"); 
    

    useEffect(() => {
      
        window.addEventListener('scroll', handleScroll);
        scrollPosition > 20 ? setmyClassName("navbar sticky") : setmyClassName("navbar");
        scrollPosition > 500 ? setmyClassName2("scroll-up-btn show") : setmyClassName2("scroll-up-btn");
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);
    


    const handleMove = () => {
        window.scrollTo({ top: 0, behavior: "smooth" }); // here it goes
      };




    return (
        <>
            <div className={myClassName2} onClick={handleMove}>
        <i className="fa fa-angle-up" ></i>
    </div>
    <nav className={myClassName}>
        <div className="max-width">
			<div className="row" style={{display:'flex', alignItems:'center'}}> 
			<img src={appleIcon} className="logo" alt="logo-FOFANA" />
            <div className="logo"><a href="#home">Tali<span>by</span></a></div>
			</div>
            <ul className={`menu ${navbarMenuToggle}`}>
                {itemsNavbar.map((item, index)=>(
                    <li key={`${item}-${index}`} onClick={handleClick}><a href={item.cible} className="menu-btn">{item.name} </a></li>
                ))}

            </ul>

            <div className= {`menu-btn ${navbarMenuToggle}`} onClick={handleClick}>
                <i className= {`fa fa-bars ${navbarMenuToggle}`}></i>
            </div>

        </div>
    </nav>
        </>
    );
}

export default Navbar;