import React, {useState} from 'react';



function Contact() {

                const [name, setName] = useState('')
                const [email, setEmail] = useState('')
                const [message, setMessage] = useState('')

                const [error, setError] = useState('')

                const handleSubmit = (e)=>{
                    e.preventDefault();
                    e.stopPropagation();

                
                    fetch("https://formcarry.com/s/4k7EXCOXuJ", {
                        method: 'POST',
                        headers: { 
                          "Accept": "application/json",
                          "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ name, email, message })
                      })
                      .then(response => response.json())
                      .then(response => {
                        if (response.code === 200) {
                          alert("We received your submission, thank you!");
                          setName('');
                          setEmail('');
                          setMessage('');
                        }
                        else if(response.code === 422){
                          // Field validation failed
                          setError(response.message)
                        }
                        else {
                          // other error from formcarry
                          setError(response.message)
                        }
                      })
                      .catch(error => {
                        // request related error.
                        setError(error.message ? error.message : error);
                      });
                    




                }

    return (
        <>
                <section className="contact" id="contact">
        <div className="max-width">
            <h2 className="title">Contacts</h2>
            <div className="contact-content">
                <div className="column left">
                    <div className="text">Me joindre</div>
                    <p>Avez-vous des questions..? <br/> Parfait, cet espace est celui qu'il vous faut. Nous répondons le plus rapidement possible à toutes vos questions.
                    <br/>Laissez-nous votre message et nos vous recontacterons. </p>
                    <div className="icons">
                        <div className="row">
                            <i className="fa fa-user"></i>
                            <div className="info">
                                <div className="head">Nom</div>
                                <div className="sub-title">Taliby FOFANA</div>
                            </div>
                        </div>
                        <div className="row">
                           
							<i className="fa fa-map-marker"></i>
                            <div className="info">
                                <div className="head">Adresse</div>
                                <div className="sub-title">Montréal, Canada</div>
                            </div>
                        </div>
                        <div className="row">
                            <i className="fa fa-envelope"></i>
                            <div className="info">
                                <div className="head">Email</div>
                                <div className="sub-title"><a href="mailto:infos@talibyfofana.net"> infos@talibyfofana.net</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column right">
                    <div className="text">Message</div>



                    <form onSubmit={(e) => handleSubmit(e)}  method="POST" action="mailto:d.kalossy@gmail.com" encType="text/plain">

                        <div className="fields"> 
                            <div className="field name">
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Nom" id='nom'  required />
                            </div>
                            <div className="field email">
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="Email" id='email' autoComplete="off"  aria-describedby="emailHelp" required />
                            </div>
                        </div>
                        <div className="field">
                            <input type="text" placeholder="Sujet" id='sujet' required/>
                        </div>
                        <div className="field textarea">
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} cols="30" rows="10" placeholder="Message..." id='message' required></textarea>
                        </div>
                        <div className="button-area">
                            <button type="submit">Envoyer message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <span>Created By <a href="https://fofanataliby.net/">Taliby FOFANA</a> | <span className="fa fa-copyright"></span> 2022 </span>
    </footer>

   
            
        </>
    );
}

export default Contact;