export const itemsServices = [
  

    {icon: 'fa fa-laptop',
    name:'Support helpdesk',
   content:'Systèmes d\'exploitation, déploiement logiciels tiers'},

    {icon: 'fa fa-line-chart',
     name:'Gestion Projet Web',
    content:'Sites vitrine, e-commerce, évènementiel, intranet'},

    {icon: 'fa fa-server',
    name:'Réseaux informatique',
   content:'Déploiement, maintenance réseau informatique'},

    {icon: 'fa fa-cog',
     name:'Interface Admin',
    content:'Outils spécifiques d\'administration'},

    {icon: 'fa fa-paint-brush',
    name:'Web Design',
   content:'templates web, logos'},

    {icon: 'fa fa-file-code-o',
     name:'Apps Design',
    content:'Conception interface graphique'},
]


	
				
				