
import './assets/styles/css/style.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";






import About from './components/About';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Services from './components/Services';
import Skills from './components/Skills';

function App() {

  


  return (
    <div className="App">
       <div className="scroll-up-btn">
        <i className="fa fa-angle-up"></i>
    </div>
      <Navbar/>
      <Home/>
      <About/>
      <Services/>
      <Skills/>
      <Projects/>
      <Contact/>
    
      
      
      
    </div>
  );
}

export default App;
